import { StorageService } from './web-storage.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RespuestaModel } from '../models/respuesta.model';
// import *  as configuration from '../../assets/conf/config.js';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class MainService {

  gatewayArchivos = 'http://10.10.0.32:8083/';

  //QA
 /*  gatewayApi = 'http://10.10.0.32:8097/Microservicios/'
  gatewayApiBoveda = 'http://10.10.0.32:8097/Microservicios/'; */

  /* gatewayApi = ''
  gatewayApiBoveda = '' */
  configuracion: any;

  //DEV
  /* gatewayApiBoveda = 'http://localhost:8090/Microservicios/';
  gatewayApi = 'http://localhost:8090/Microservicios/';
  gatewayApiBoveda = 'https://localhost:44362/';
  gatewayApi = 'https://localhost:44362/';*/

  //PROD
  //gatewayApiBoveda = 'https://localhost:44362/';
    gatewayApiBoveda = 'https://simaf.conade.gob.mx/Microservicios/';
  //gatewayApi = 'https://localhost:44362/';
    gatewayApi = 'https://simaf.conade.gob.mx/Microservicios/';


    //SE OBTIENE LA URL DEL SERVICIO ENCRIPTADA DESDE config.js
   //servicio_1 = configuration.urlServicio1;
    secretKey = 'conade';

  constructor(public httpClient: HttpClient) {
    // console.log(this.encrypt('https://simaf.conade.gob.mx/Microservicios/'))
    //console.log(this.decrypt('U2FsdGVkX18tbxjT4sTX/DexMLGaM33U525ARhg7sZuc0oQi2ciQ4UyeWumvhfUyZTfsqiTKUc0ZF/xduliJKA=='))
    //console.log(this.decrypt('U2FsdGVkX18ygvNZIZUs3fV0uvp/Zw/X6YjQLURS8f9uXuaEne7ggDCmpiPsUOSfGrA5Wb+ET3s2Lv6KJsnwIg=='))

    //console.log(this.decrypt(this.servicio_1));

    //console.log(this.encrypt('  '))
    //console.log(this.decrypt(this.servicio_1));

    //SE DESENCRIPTA LA URL DEL SERVICIO

    //const respuesta = this.obtenerConfiguration();
    //this.configuracion = respuesta[0];

    //this.gatewayApi = this.decrypt(this.servicio_1);
    //this.gatewayApiBoveda = this.decrypt(this.servicio_1);
    /* debugger
    if(this.configuracion){
      this.gatewayApi = this.decrypt(this.configuracion.urlServicio1);
      this.gatewayApiBoveda = this.decrypt(this.configuracion.urlServicio1);
    } */

  }
  getAsync(url: string): Promise<any> {

    return new Promise(resolve => {
      const subscription = this.httpClient.get<any>(url)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  postAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.post(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  putAsync(url: string, objeto: any): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.put(url, objeto)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);
          },
          error => {

            subscription.unsubscribe();

            return resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }

  deleteAsync(url: string): Promise<any> {

    return new Promise(resolve => {

      const subscription = this.httpClient.delete<any>(url)
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data ? data : { EXITO: true } as RespuestaModel);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: null
            } as RespuestaModel);
          });
    });
  }


  get() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/plantilla.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }

  obtenerUsuariosCSV_() {
    return new Promise(resolve => {
      const subscription = this.httpClient.get('../../../../assets/datos/usuarios.json', { responseType: 'text' })
        .subscribe(
          data => {

            subscription.unsubscribe();

            resolve(data);

          }, error => {

            subscription.unsubscribe();

            resolve({
              EXITO: false,
              MENSAJE: error.message.toString(),
              RESPUESTA: error.error.text
            } as RespuestaModel);
          });
    });
  }



  async obtenerConfiguration(){

      let a = null;
      const subscription = this.httpClient.get('../../assets/conf/configuration.json').subscribe(data => {
        this.configuracion = data[0];
        this.gatewayApi = this.decrypt(this.configuracion.urlServicio1);
        this.gatewayApiBoveda = this.decrypt(this.configuracion.urlServicio1);
      });
      return a;
  }

  encrypt(value: string): string {
    return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
  }

  decrypt(textToDecrypt: string) {
    return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
  }

}

