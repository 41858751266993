import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioCultura } from 'src/app/models/cultura/usuario-cultura-model';
import { RespuestaModel } from 'src/app/models/respuesta.model';




import { MainService } from '../main.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioCulturaService extends MainService {

  constructor(public http: HttpClient) {
    super(http);
  }

  login(objeto: any) : Promise<RespuestaModel> {
    return this.postAsync(this.gatewayApi + 'CF/Usuarios/Autenticacion', objeto);
  }

  public async InsertaUsuario(UsuarioInserta: any) : Promise<RespuestaModel>{
      return await this.postAsync(this.gatewayApi+'CF/Usuarios/Insertar', UsuarioInserta);
  }

  public async BajaUsuario(UsuarioBaja: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApi+'CF/Usuarios/Eliminar',UsuarioBaja);
  }

  public async CambiaPass(UsiarioModifcar: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApi+'CF/Usuarios/ResetearContrasena',UsiarioModifcar);
  }

  public async ObtieneUsuarios(filtro: string, tipoUsuario: string)  : Promise<RespuestaModel>
  {
    return await this.getAsync(`${this.gatewayApi}CF/Usuarios/Consultar${filtro}&idTipoUsuario=${tipoUsuario}`);
  }

  public async ObtieneUsuarioId(idUsuari:number)  : Promise<RespuestaModel>
  {
      return await this.getAsync(this.gatewayApi+'CF/Usuarios/Consultar?id='+idUsuari)
  }

  public async ModificaUsuario(UsiarioModifcar: any) : Promise<RespuestaModel>
  {
      return await this.postAsync(this.gatewayApi+'CF/Usuarios/Actualizar',UsiarioModifcar);
  }

}
