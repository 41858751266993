import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/components/home', title: 'Inicio', icon: 'fas fa-home text-primary', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], Conade:true, instituto:true, Coordinador: true, Promotor:true
    },
    {
      path: '/components/centro-activacion', title: 'Espacio Activación', icon: 'fas fa-map-marker-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],Conade:true, instituto:false, Coordinador: true, Promotor:true
    },
    {
      path: '/components/usuarios', title: 'Usuarios', icon: 'fas fa-users-cog', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        {
          path: '/components/usuarios/coordinador', title: 'Coordinador', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[],Conade:true, instituto:true, Coordinador: false, Promotor:true
        },
        {
          path: '/components/usuarios/promotor', title: 'Promotor', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[], Conade:true, instituto:false, Coordinador: true, Promotor:false
        }
      ], Conade:true, instituto:true, Coordinador: true, Promotor:false
    },
    {
      path: '/components/activacion-participantes', title: 'Activación', icon: 'fas fa-running', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],Conade:true, instituto:false, Coordinador: false, Promotor:true
    },
    {
      //los coordinadores de eventos masivos cargarian los eventos masivos
      //path: '/components/eventos-masivos', title: 'Eventos masivos', icon: 'fas fa-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],Conade:false, instituto:false, Coordinador: false, Promotor:true
      path: '/components/eventos-masivos', title: 'Eventos y capacitaciones', icon: 'fas fa-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],Conade:true, instituto:false, Coordinador: true, Promotor:false
    }
    ,
    {
      path: '/components/tableros', title: 'Tableros', icon: 'fas fa-columns', class: 'sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
        /* {
          path: '/components/tableros/eventos', title: 'Eventos', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[], Coordinador: true, Promotor:true
        }, */
        {
          path: '/components/tableros/coordinador', title: 'Coordinador', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[], Conade:true, instituto:true, Coordinador: false, Promotor:false
        },
        {
          path: '/components/tableros/promotor', title: 'Promotor', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[], Conade:true, instituto:false, Coordinador: true, Promotor:true
        },
        {
          path: '/components/tableros/conade', title: 'Conade', icon: 'fa fa-dot-circle-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu:[], Conade:false, instituto:false, Coordinador: false, Promotor:false
        },

      ], Conade:true, instituto:true, Coordinador: true, Promotor:true
    }
];
