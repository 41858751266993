import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Usuario } from "src/app/models/usuario.model";
import { SidebarService } from "../sidebar/sidebar.service";
import { VariablesEnum } from "src/app/auth/enum/variables.enum";
import { LocalStorageService } from "src/app/services/localstorage.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VariablesService } from '../../services/variableGL.service';
import { UsuarioCultura } from "src/app/models/cultura/usuario-cultura-model";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @ViewChild('modalChangePass', { static: false }) modalChangePass: TemplateRef<any>;
  modalRefPass: any;

  authUs: UsuarioCultura;
  nombreCompleto: string;
  constructor(
    public sidebarservice: SidebarService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private variablesService: VariablesService
    ) {
      this.authUs = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));
      this.nombreCompleto = this.authUs.nombre+ " " + this.authUs.aPaterno + " " + this.authUs.aMaterno;
    }

  toggleSidebar() {
    
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

      if ($("#wrapper").hasClass("nav-collapsed")) {
          // unpin sidebar when hovered
          $("#wrapper").removeClass("nav-collapsed");
          $("#sidebar-wrapper").unbind( "hover");
      } else {
          $("#wrapper").addClass("nav-collapsed");
          $("#sidebar-wrapper").hover(
              function () {
                  $("#wrapper").addClass("sidebar-hovered");
              },
              function () {
                  $("#wrapper").removeClass("sidebar-hovered");
              }
          )

      }

  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  ngOnInit() {}

  Logout(){
    this.localStorageService.clearToken();
    this.router.navigate(['auth/signin']);
  }

  openModal(){
    this.variablesService.modalCambiaContrasena.next(true);
  }

}
