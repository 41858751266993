import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CategoriasTKY } from '../../models/categorias.model';

@Component({
  selector: 'app-items-cats',
  templateUrl: './items-cats.component.html',
  styleUrls: ['./items-cats.component.scss']
})
export class ItemsCatsComponent implements OnInit {

  @Input() _categoria: CategoriasTKY;
  @Output() _selec = new EventEmitter<CategoriasTKY>();
  active: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  toogleActive(_cat: CategoriasTKY){
    this._selec.emit(_cat);
    _cat.Selected = true;
  }

}
