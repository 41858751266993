<!--Start topbar header-->
<header class="topbar-nav">
    <nav id="header-setting" class="navbar navbar-expand fixed-top  color-header bg-theme6">
        <ul class="navbar-nav mr-auto align-items-center">
            <li class="nav-item">
                <a class="nav-link toggle-menu" (click)="toggleSidebar()">
                    <i class="icon-menu menu-icon"></i>
                </a>
            </li>
        </ul>
        <h4 class="TITULO-HOME">
            Sistema de Informe de Metas de Activación Física
          </h4>
        <ul class="navbar-nav align-items-center right-nav-link">
            <li class="nav-item border-left" ngbDropdown placement="bottom-right">
                <a href="javascript:;" class="nav-link dropdown-toggle dropdown-toggle-nocaret" id="dropdownBasic4" ngbDropdownToggle>
                    <i class="fas fa-user-cog"></i>
                    <p class="ml-3 mb-0 float-right extra-small-font hidden-xs">
                        {{ authUs.nombreCompleto }}
                    </p>
                </a>
                <ul ngbDropdownMenu aria-labelledby="dropdownBasic4" class="dropdown-menu dropdown-menu-right animate__animated animate__fadeInUp animate__faster">
                    <li class="dropdown-item user-details">
                        <a href="javaScript:;">
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-2 user-title">{{ authUs.nombreCompleto }}</h6>
                                    <p class="user-subtitle">{{ authUs.usuario }} | {{ authUs.tipoUsuario }}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="dropdown-divider"></li>
                    <li class="dropdown-item cp" (click)="openModal()"><i class="fas fa-cogs cp"></i> Cambiar contraseña</li>
                    <!-- <li class="dropdown-divider"></li> -->
                    <li class="dropdown-item cp" (click)="Logout()"><i class="fas fa-power-off cp"></i> Cerrar sesión</li>
                </ul>
            </li>
        </ul>
    </nav>
</header>
<!--End topbar header-->
