import { Component, OnInit } from '@angular/core';
import { TipoUsuarioEnum } from 'src/app/auth/enum/tipo-usuario.enum';
import { VariablesEnum } from 'src/app/auth/enum/variables.enum';
import { UsuarioCultura } from 'src/app/models/cultura/usuario-cultura-model';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: [`
      img {
        background: #752A31;
        width: 3.5em !important;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        border-radius: .5em;
        margin-top: -.3em;
      }
    `],
     styleUrls: ['./sidebar.component.css']

})

export class SidebarComponent implements OnInit {
    public menuItems: any[];

    authUS = new UsuarioCultura();

    constructor(
      private localStorageService: LocalStorageService
    ) {
    }


    async ngOnInit() {
      this.authUS = JSON.parse(this.localStorageService.getJsonValue(VariablesEnum.usuario_conade));

        $.getScript('./assets/js/app-sidebar.js');
        this.menuItems = ROUTES;

        //this.menuItems = ROUTES.filter(menuItem => menuItem);

        theme6();

        function theme6() {
          $('#sidebar-wrapper').attr('class', 'sidebar bg-theme bg-theme6');
          $('#active-class').attr('class', 'navigation active1');

          $('#header-setting').attr('class', 'navbar navbar-expand fixed-top color-header bg-theme6');

        }
    }



    public valido(menu: RouteInfo){
      switch(this.authUS.idTipoUsuario){
        case TipoUsuarioEnum.CONADE:
            if(menu.Conade){
              return true;
            } else {
              return false;
            }
          break;
          case TipoUsuarioEnum.INSTITUTO:
            if(menu.instituto){
              return true;
            } else {
              return false;
            }
          break;
          case TipoUsuarioEnum.COORDINADOR:
          /*  if(menu.Coordinador){
              return true;
            } else {
              return false;
            } */

            if(menu.Coordinador){



              if(((this.authUS.TipoCoordinador)?(this.authUS.TipoCoordinador):"")){
                //SE QUITA PARA QUE LOS COORDINADORES CARGUEN LOS EVENTOS MASIVOS
                if( menu.path === '/components/tableros/promotor' || menu.path === '/components/tableros'|| menu.path === '/components/usuarios' ||menu.path === '/components/usuarios/promotor'|| menu.path === '/components/home'  || menu.path === '/components/eventos-masivos' ||  menu.path === '/components/centro-activacion'){
                  return true
                } else {
                  return false;
                }
              }else{
                if(menu.path !== '/components/tableros/eventos'&&menu.path !== '/components/eventos-masivos'){
                  return true;
                } else {
                  return false;
                }
              }

              /* if((menu.title === "Usuarios")||(menu.title === "Tableros")||(menu.title === "Eventos")||(menu.title === "Promotor")){
                return true
              } else {
                return false;
              } */

            }else{
              return false;
            }

          break;
        case TipoUsuarioEnum.PROMOTOR:

          if (menu.Promotor) {
            /* if(((this.authUS.TipoCoordinador)?(this.authUS.TipoCoordinador):"").toLowerCase() === "eventos masivos"){
              if(menu.title === "Eventos masivos"){
                return true
              } else {
                return false;
              }
            }else{
              return true;
            } */
          if((menu.title !== "Eventos masivos") && (menu.title !== "Eventos")){
            if(((this.authUS.TipoCoordinador)?(this.authUS.TipoCoordinador):"").toLowerCase() === "eventos masivos"){
                return false
              }else{
                return true
              }
            } else {

                if(((this.authUS.TipoCoordinador)?(this.authUS.TipoCoordinador):"").toLowerCase() === "eventos masivos"){
                  return true
                }else{
                  return false
                }

              }
          } else {
            return false;
          }
          break;
      }
    }
}
