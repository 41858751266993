export class UsuarioCultura{
  Accion?: string;
  id?: number;
  idUsuarioRegistro?: number;
  idTipoUsuario?: number;
  idTipoCoordinador?: number;
  TipoCoordinador?: string;
  tipoUsuario?: string;
  idOrganismoBoveda?: number;
  Organismo?: string;
  idEstatusUsuario?: number;
  nombre?: string;
  aPaterno?: string;
  aMaterno?: string;
  usuario?: string;
  nombreCompleto?: string;
  contrasena?: string;
  fechaCaptura?: Date;
  activo?: boolean;
  idTipoCoordinador_Coordinador?: number;
  tipoCoordinador_Coordinador?: string;
  usuario_organismo?: string;
}
